<template>
  <!-- 图片的气泡 -->
  <div
    class="chat-robot-box text-message"
    style="width: fit-content;"
  >
    <img ref="image" :src="message.content" alt="">
  </div>
</template>

<script>
export default {
  name: 'BubbleImage',
  props: {
    message: Object
  },
  mounted () {
    const image = this.$refs.image
    const self = this

    image.addEventListener('load', function () {
      // 图片加载成功通知列表更新高度
      setTimeout(() => {
        self.$store.commit('updateFreshNeed', false)
      }, 120)
    })
  }
}
</script>
